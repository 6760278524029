import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
// styles
import clsx from 'clsx';
import styles from './moolax.module.css';
import itemStyles from '../components/item/cases.module.css';
// components
import Layout from '../components/item/layout';
import Ava from '../components/ava';
import Team from '../components/team';
import Technology from 'components/technology';
// other
import { meta } from '../../../metaData';

const MoolaxCase = () => {
  const data = useStaticQuery(graphql`
    query {
      laptop: file(relativePath: { eq: "moolax/laptop.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      julien: file(relativePath: { eq: "moolax/julien.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      personas: file(relativePath: { eq: "moolax/user-personas.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen1: file(relativePath: { eq: "moolax/screen-1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "moolax/screen-2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "moolax/screen-3.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen4: file(relativePath: { eq: "moolax/screen-4.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen5: file(relativePath: { eq: "moolax/screen-5.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen6: file(relativePath: { eq: "moolax/screen-6.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen7: file(relativePath: { eq: "moolax/screen-7.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen8: file(relativePath: { eq: "moolax/screen-8.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen9: file(relativePath: { eq: "moolax/screen-9.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      background: file(relativePath: { eq: "moolax/bg-screen.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <Layout
      score={['Fintech', 'USA', '2024', 'Ongoing']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Developing an MVP of a Smart Cashflow Manager"
      subTitle="How Brocoders helped to build an MVP for a trendy FinTech startup offering a streamlined cash flow management solution for modern specialists and freelancers to drive their financial insights."
      metaData={meta.moolax}
      isRed={false}
    >
      <div className={clsx(itemStyles.inner, styles.narrowInner)}>
        <p className={itemStyles.preamble}>
          MoolaX is a stylish, easy-to-use and adaptive financial tool that is designed specifically
          for the dynamic lifestyles of modern professionals and entrepreneurs. Gen Z is the
          generation that seeks freedom in their work choices, creates new forms of employment, and
          often works on several projects at the same time. They need a centralized accounting tool
          to meet their updated needs and allow them to stay financially organized. MoolaX is here
          to track various income sources, pending payments, and real-time cash flow insights for a
          new generation of freelancers, part-time workers and service providers.
        </p>
      </div>
      <div className={clsx(itemStyles.inner, styles.narrowInner)}>
        <h2 className={clsx(styles.title, styles.titleUp)}>Our client</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          For this project, Brocoders collaborated with Julien Gray, the owner of a boutique UX/UI
          design agency, Kayen Peppa. Julien has over twenty years of experience in user interface
          and experience design. This background has shaped his expertise and unique approach to
          user engagement. At the beginning of our collaboration, he approached Brocoders with an
          exciting Fintech project aimed at a variety of freelancers and professionals so that they
          can easily manage multiple sources of income. In our first few chats, Julien even
          complimented our website's design, which is a meaningful endorsement to our team from
          someone with his level of expertise. This positive first impression set the stage for a
          successful partnership.
        </p>
        <Ava
          img={data.julien.childImageSharp.fluid}
          caption={
            <React.Fragment>
              <b>Julien Gray</b> <br />
              Founder of{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
                href="https://coming-soon-dev.moolax.co/"
              >
                Moolax
              </a>
              ,
              <br />
              Sr. Full Stack UX & UI Product Designer <br />
              at{' '}
              <a
                href="https://www.kayenpeppa.com/"
                className={styles.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                Kayen Peppa
              </a>
            </React.Fragment>
          }
          className={styles.ava}
        />
        <h2 className={styles.title}>Business Idea</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          In today's hybrid remote work environment, young professionals juggle many income streams,
          such as freelancing, part-timer work, content creation, side hustles, and even rental
          income. They work in creative fields, IT industries, social media, or running an online
          business. Managing these diverse sources can be overwhelming, leading to financial chaos.
          Traditional accounting tools are unable to effectively address these challenges because
          they are focused on corporate accountants and miss out on features such as tracking
          expected payments. These solutions might be complex, clunky, and outdated in style.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Recognizing these challenges, MoolaX is designed to meet the needs of a diverse audience
          of modern, self-reliant professionals who value ​​intuitive, visually appealing, and
          convenient tools. The app provides a fresh, user-centric experience that resonates with a
          tech-savvy audience. With its elegant design and practical features, MoolaX makes managing
          finances fun and easy, perfectly suited to the expectations of the new generation,
          including:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsList)}>
          <li>Freelancers and contractors who manage clients’ payments and projects.</li>
          <li>
            Service providers such as small clinics, beauty salons, and personal trainers who track
            income and expenses.
          </li>
          <li>Property managers and event planners who organize revenue and costs.</li>
          <li>
            Content creators, consultants, and tutors who combine part-time and full-time jobs.
          </li>
          <li>Social club owners who manage membership finances, and many others.</li>
        </ul>
      </div>
      <BackgroundImage
        fluid={data.laptop.childImageSharp.fluid}
        className={styles.bgImg}
        style={{
          backgroundPosition: 'top left 15%',
        }}
      />
      <div className={clsx(itemStyles.inner, styles.narrowInner)}>
        <h2 className={styles.title}>User personas</h2>
      </div>
      <div className={itemStyles.inner}>
        <Img
          fluid={data.personas.childImageSharp.fluid}
          alt="application screenshot"
          className={styles.personas}
        />
      </div>
      <div className={clsx(itemStyles.inner, styles.narrowInner)}>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Moolax was designed as a solution that:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsList)}>
          <li>Consolidate different payment sources into one intuitive interface;</li>
          <li>Provide a unique feature for tracking both received and incoming transactions;</li>
          <li>Have manageable analytics for data-driven insights.</li>
        </ul>
        <h2 className={clsx(styles.title, styles.titleMarginTop)}>Client's request</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Julien Gray approached us with a request to develop an MVP for his project. At that point,
          he already had a design in place and was looking for a team to develop a web app.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>The project included:</p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsList)}>
          <li>
            Developing a web application with advanced data visualization and an adaptable
            interface.
          </li>
          <li>Creating a landing page to attract potential users before launch.</li>
        </ul>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Julian wanted a product that addressed the expectations of digital natives. It had to be
          something fresh, modern, and user-centric. The interface played a key role, as it had to
          be engaging and understandable for a tech-savvy audience. He wanted the app to not only
          help manage finances, but to do so in a fun and easy way.
        </p>
        <h2 className={clsx(styles.title, styles.titleMarginTop)}>Working Process</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Brocoders took on the task of developing a financial web application and a landing page.
          We initiated both tasks simultaneously with a focus on integrating essential financial
          tool functionalities within the web application.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Following the core development, we created a Coming Soon page to announce the product
          launch and engage potential users. The MVP release was set for six months from the
          project’s start.
        </p>
        <h2 className={clsx(styles.title, styles.titleMarginTop)}>Our team</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          For this project, Brocoders brought together a team of skilled professionals. So the team
          included:
        </p>
        <Team
          color="#9948EA"
          list={[
            { count: 1, title: 'Project\nmanager' },
            { count: 1, title: 'Front-end\ndeveloper' },
            { count: 1, title: 'Back-end\ndeveloper' },
            { count: 1, title: 'Quality Assurance\nspecialist' },
          ]}
          containerClassName={styles.team}
          memberClassName={styles.teamMember}
          isMobileList={false}
        />
        <h2 className={clsx(styles.title, styles.titleMarginTop)}>PM moderation</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          At Brocoders, we work a lot on MVPs for startups, so our project managers are experienced
          and clearly know how to prioritize features and set up a process to deliver the product on
          time. At this stage, it is very important to keep the project focused and lean, avoiding
          unnecessary expansion of scope - only the core features for testing are needed. This
          expert moderation ensures the effective implementation of the vision for the final
          product, meeting the unique needs of startups.
        </p>
        <h2 className={clsx(styles.title, styles.titleMarginTop)}>What did we do?</h2>
        <h3 className={styles.subTitle}>Technologies and Integrations</h3>
        <Technology
          list={['nextJs', 'nodejs', 'react', 'tailwind', 'stripe', 'strapi']}
          listStyle={styles.technology}
        />
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          The use of a modern technology stack ensures reliable tracking of financial data. We built
          the product using tech-stack:
        </p>
        <h3 className={styles.subTitle}>Payment integration</h3>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          We integrated{' '}
          <a
            href="https://stripe.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Stripe
          </a>{' '}
          for seamless payments and refunds. This payment gateway provider is easy to integrate,
          supports various payment methods and offers a modern, intuitive user experience.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Additionally, Stripe offers strong data analytics and reporting tools to give Julien, as a
          product owner, insights into payment trends and customer behavior. He can track
          subscriptions and payments: see subscription status, transaction history, manage refunds,
          receive event notifications, and analyze revenue.
        </p>
        <h3 className={styles.subTitle}>Strapi backend integration</h3>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          <a
            href="https://strapi.io/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Strapi
          </a>
          , integrated as a backend, provides an efficient content management system. It optimizes
          content delivery, provides seamless API integration with the frontend, and improves user
          experience across all devices. Strapi can be customized to suit any needs, and it prepares
          the platform for scalable growth.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          With Strapi, Julien now has full control over the content and structure of the website.
          The platform allows the owner to perform various tasks on his own. He can add blog pages,
          upload multimedia content, or even create new landing page sections for a promo. Plus, the
          owner can customize SEO settings, manage metadata, and update page URLs to enhance search
          engine visibility.
        </p>
        <h2 className={clsx(styles.title, styles.titleMarginTop)}>Delivered solution</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          MoolaX is an intuitive cash flow manager that allows entrepreneurs to track their
          financial performance. For now, it is available in the English language.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          The MoolaX interface offers a modern, user-friendly design that is both stylish and
          practical. It is easy to navigate, making it simple for users to come back and update
          their financial data on a regular basis. The layout feels fresh and inviting, encouraging
          users to stay engaged and manage their information easily.
        </p>
        <h3 className={styles.subTitle}>Core Features of MoolaX</h3>
        <h4 className={styles.moduleTitle}>Cashflow Dashboard</h4>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          The MoolaX dashboard summarizes financial data in one place. It brings together key
          metrics, such as Money In and Money Out, and cash flow trends in an easy-to-understand
          format. Here, users can track analytics on income and expenses for different time periods,
          such as the past month or the past year. Thus, it is specially designed to offer insights
          at a glance for busy entrepreneurs.
        </p>
      </div>
      <div className={itemStyles.inner}>
        <Img
          fluid={data.screen1.childImageSharp.fluid}
          alt="application screenshot"
          className={styles.image}
        />
      </div>
      <div className={clsx(itemStyles.inner, styles.narrowInner)}>
        <h4 className={styles.moduleTitle}>Profit Tracker for Business</h4>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          This tab allows users to add and manage all their business details. The MoolaX interface
          offers a list of business types to choose from, such as freelancer, employee, contractor,
          trainer, tutor, event organizer, content creator, consultant, property manager, etc.
          Clients can also customize business entries as needed, which is really convenient as
          today, most young people often work online and have different types of activities and
          income sources.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Additionally, users can also fill in the information about their clients, and enter not
          only received but incoming transactions as well. Users can see cash flow trends and track
          income for each of their businesses without losing sight of it.
        </p>
      </div>
      <div className={itemStyles.inner}>
        <Img
          fluid={data.screen2.childImageSharp.fluid}
          alt="application screenshot"
          className={styles.image}
        />
        <Img
          fluid={data.screen3.childImageSharp.fluid}
          alt="application screenshot"
          className={styles.image}
        />
      </div>
      <div className={clsx(itemStyles.inner, styles.narrowInner)}>
        <h4 className={styles.moduleTitle}>Expense Monitor</h4>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          The Expense tab is a dedicated space where users can track and manage all outgoing
          payments in detail. This feature makes it easy to categorize expenses and quickly access
          past expense records. Financial transactions are organized by week in chronological order,
          allowing users to easily track and plan financial activities.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          With clear visual data on expenses, users can see exactly where their money is going. This
          allows them to control expenses, optimize budgets, and forecast future spending.
        </p>
      </div>
      <div className={itemStyles.inner}>
        <Img
          fluid={data.screen4.childImageSharp.fluid}
          alt="application screenshot"
          className={styles.image}
        />
      </div>
      <div className={clsx(itemStyles.inner, styles.narrowInner)}>
        <h4 className={styles.moduleTitle}>Payer Manager</h4>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          The Payers tab is a centralized hub where users can manage and track all the individuals
          or organizations that make payments to their business, as well as maintain customer
          relationships. This feature allows users to view a list of payers, access detailed payment
          history, and stay on top of who has paid and who hasn't.
        </p>
      </div>
      <div className={itemStyles.inner}>
        <Img
          fluid={data.screen5.childImageSharp.fluid}
          alt="application screenshot"
          className={styles.image}
        />
      </div>

      <div className={clsx(itemStyles.inner, styles.narrowInner)}>
        <h4 className={styles.moduleTitle}>Advanced Analytics for Insights</h4>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          One of the most exciting features of MoolaX is its tools for deep analysis of business
          performance based on cash flow trends and complex formulas. The platform automatically
          generates insights on spending patterns, revenue growth, and financial trends. This allows
          users to save time on data analysis and get robust financial analytics without the need
          for financial expertise.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          The Insights tab provides users with analytics across two sections: Business insights and
          Expense insights.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          What do Business insights include, and what do app users get:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsList)}>
          <li>
            <span>Top 3 Payers by Revenue.</span> Identifies the top customers who contribute the
            most to the revenue;
          </li>
          <li>
            <span>Revenue Trend.</span> Shows income dynamics over time, highlighting growth
            patterns;
          </li>
          <li>
            <span>Average Time to Invoice Payment.</span> Shows the average time it takes to pay
            invoices, helping to estimate the speed of cash flow;
          </li>
          <li>
            <span>5 Highest Payers Revenue Concentration.</span>Indicates the percentage of revenue
            coming from the top 5 clients that reveals levels of dependency;
          </li>
          <li>
            <span>Revenue Growth Rate.</span>Tracks revenue growth rates, providing insight into
            business expansion;
          </li>
          <li>
            <span>New vs. Returning Revenue Split.</span>Distribution of revenue between new
            customers and regular customers;
          </li>
          <li>
            <span>Average Payers Payment Value.</span>Calculates the average payment size from
            customers;
          </li>
          <li>
            <span>Received vs. Incoming Payment Ratio.</span>Compares payments already received with
            pending ones;
          </li>
          <li>
            <span>Number of Payers Trend. </span>Dynamics of the number of payers.
          </li>
        </ul>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          What do Expense insights include, and what do app users get:
        </p>

        <ul className={clsx(itemStyles.bullets, styles.bulletsList)}>
          <li>
            <span>Top 3 Payees by Amount.</span>Highlights the top recipients of the payments and
            shows where most of your spending goes;
          </li>
          <li>
            <span>Expense Spending Trend.</span>Tracks spending trends over time to help identify
            peak spending periods;
          </li>
          <li>
            <span>Average Monthly Operating Cast.</span>Displays average monthly expenses, thus
            giving an idea of operating expenses;
          </li>
          <li>
            <span>5 Highest Expense Category.</span>Shows the categories with the highest costs so
            users know which areas are consuming the most resources;
          </li>
          <li>
            <span>Payees with Most Frequent Payments.</span>Creates a list of payment recipients
            with the most frequent payments, allows to track current financial obligations;
          </li>
          <li>
            <span>Expense to Revenue Ratio.</span>Compares total expenses with revenues, a metric
            for the effectiveness of cost management;
          </li>
          <li>
            <span>Revenue per Expense Dollar Ratio.</span>Measures the revenue generated for every
            dollar spent that indicates the profitability efficiency;
          </li>
          <li>
            <span>Average Payees Payment Value.</span>Provides the average payment amount to each
            recipient;
          </li>
          <li>
            <span>Numbers of Payees Trend.</span>Shows trends in the number of payment recipients
            over time, allowing you to track supplier relationships.
          </li>
        </ul>
      </div>
      <div className={itemStyles.inner}>
        <Img
          fluid={data.screen6.childImageSharp.fluid}
          alt="application screenshot"
          className={styles.image}
        />
        <Img
          fluid={data.screen7.childImageSharp.fluid}
          alt="application screenshot"
          className={styles.image}
        />
      </div>

      <div className={clsx(itemStyles.inner, styles.narrowInner)}>
        <h3 className={styles.subTitle}>Pricing Plan</h3>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>Pricing Plan</p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsList)}>
          <li>
            Currently, MoolaX offers a Special Launch Offer for the first 100 users, which includes
            3 months of free access for the premium subscription.
          </li>
          <li>Basically, MoolaX offers three pricing plans to meet different business needs.</li>
          <li>All plans guarantee 14 days of free trial without asking for credit card details</li>
          <li>
            Monthly and yearly subscriptions are available. With the yearly option, users may save
            15 percent.
          </li>
          <li>
            A set of features included in all plans: Cashflow Management, Advanced Analytics, Profit
            Tracker, Expense Monitor, Payer Manager, and Comprehensive Reporting.
          </li>
        </ul>
        <h4 className={styles.moduleTitle}>Starter Plan</h4>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          This plan is free of charge. Here, it's possible to launch two businesses, five payers per
          business, ten expense categories and five payees per expense.
        </p>
        <h4 className={styles.moduleTitle}>Money Manager</h4>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Users pay 12 USD per month or 120 USD yearly and manage five businesses, 15 payers per
          business, 20 expense categories and 15 payees per expense.
        </p>
        <h4 className={styles.moduleTitle}>Profit Pro</h4>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Users pay 19 USD per month or 192 USD yearly and control the cash flow of income and
          expenses with no limits. Thus, the pricing policy is rather flexible and meets the
          specific financial needs. Each user, be it a freelancer, employee, real estate manager,
          consultant or trainer, can choose a plan in accordance with the stage of business
          development.
        </p>
      </div>
      <div className={itemStyles.inner}>
        <Img
          fluid={data.screen8.childImageSharp.fluid}
          alt="application screenshot"
          className={styles.image}
        />
      </div>
      <div className={clsx(itemStyles.inner, styles.narrowInner)}>
        <h2 className={clsx(styles.title, styles.titleMarginTop)}>Future Prospects</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          MoolaX already provides entrepreneurs and freelancers with simplified tools to manage cash
          flow, expenses, and income. Looking ahead, the MoolaX team plans to introduce advanced
          analytics to provide deeper insight into financial trends and the business's overall
          health.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Further plans will focus on automating more aspects of financial management to improve
          user experience. The ultimate goal is to help everyone who manages numerous income streams
          and side hustles to handle their finances and make smarter, data-driven business
          decisions.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}></p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}></p>
      </div>

      <BackgroundImage
        fluid={data.background.childImageSharp.fluid}
        className={styles.bgImg}
        style={{
          backgroundPosition: 'top center',
        }}
      />

      <div className={clsx(itemStyles.inner, styles.narrowInner)}>
        <h2 className={styles.title}>The Results</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Using MoolaX, young professionals can stay on top of all their finances in real time. It
          helps them manage money better, avoid missing payments, and keep multiple cash flows
          organized - so they can focus more on the work and less on finances.{' '}
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          MoolaX has already shown promising results in its early stages.{' '}
          <span className={styles.bold}>
            100+ users have signed up to the product launch waitlist during the first 2 weeks
          </span>
          , indicating a high level of interest in the platform.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          These metrics highlight a solid base for growth and relevant market demand for MoolaX. It
          has the potential to generate significant profitability.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          MoolaX benefits as an all-in-one financial tool:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsList)}>
          <li>
            Entrepreneurs get a clear picture of financial performance, which leads to improved
            decision-making processes.
          </li>
          <li>
            Thanks to payment management, customers receive their money on time, which creates a
            positive experience.
          </li>
          <li>
            Thanks to the automation of financial management, self-employed youth can focus as much
            as possible on their business.
          </li>
        </ul>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Tracking personal finances seems complicated and annoying. MoolaX transforms this
          experience, making it simple and enjoyable while fostering a lasting habit. With a
          visually appealing interface, easy data entry, and an intuitive approach to managing
          finances, MoolaX encourages regular engagement. These small yet significant details make
          all the difference.
        </p>
        <a
          className={styles.learnMore}
          target="_blank"
          rel="noopener noreferrer"
          href="https://coming-soon-dev.moolax.co/"
        >
          Join beta
        </a>
      </div>
      <div className={clsx(itemStyles.inner, styles.additionalButtom)}>
        <Img
          fluid={data.screen9.childImageSharp.fluid}
          alt="application screenshot"
          className={styles.image}
        />
      </div>
    </Layout>
  );
};
export default MoolaxCase;
